import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-view-by-id-product-model',
  templateUrl: './view-by-id-product-model.component.html',
  styleUrls: ['./view-by-id-product-model.component.css']
})
export class ViewByIdProductModelComponent {

  productModelDetail: any;

  constructor( private service: AuthService, private router: Router, private _api: ApiService, private route: ActivatedRoute) {
     
  }
  ngOnInit() {
    return this._api.getTypeRequestById('product-category',this.route.snapshot.params['id']).subscribe((res: any) => {
      this.productModelDetail = res.data;
    });
  }

}
