<mat-card>
    <mat-card-header>
        <h2>Customer Listing</h2>
       
       
    </mat-card-header>
    <mat-card-content>
        <div class="mat-elevation-z8">
            <button (click)="addcustomer()" mat-raised-button color="primary">Add Customer</button>
            <table mat-table matSort  [dataSource]="dataSource">
          
              <!-- Position Column -->
              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> Code </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
              </ng-container>
          
              <!-- Name Column -->
              <ng-container  matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
          
              <!-- Weight Column -->
              <ng-container matColumnDef="creditlimit">
                <th mat-header-cell *matHeaderCellDef> Creditlimit </th>
                <td mat-cell *matCellDef="let element"> {{element.Creditlimit}} </td>
              </ng-container>
          
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">  
                    <button (click)="updatecustomer(element.id)" mat-raised-button color="primary">Edit</button>
                    <button (click)="removecustomer(element.id)" mat-raised-button color="accent">Delete</button>
                </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          
            <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                           showFirstLastButtons
                           aria-label="Select page of periodic elements">
            </mat-paginator>
          </div>
    </mat-card-content>
  </mat-card>


