<div class="wrapper" >

    <!-- Preloader -->
    <!-- <div class="preloader flex-column justify-content-center align-items-center">
      <img class="animation__wobble" src="assets/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
    </div> -->
  
    <!-- Navbar -->
    <app-header></app-header>
    <!-- /.navbar -->
  
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a href="index3.html" class="brand-link">
        <img src="assets/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-light">Admin</span>
      </a>
  
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image">
            <!-- <img src="assets/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image"> -->
          </div>
          <div class="info">
            <a href="#" class="d-block"></a>
          </div>
        </div>
  
        <!-- SidebarSearch Form -->
        
  
        <app-sidebar></app-sidebar>
      </div>
      <!-- /.sidebar -->
    </aside>
  
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
             
            </div><!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a [routerLink] = "['/dashboard']">Home</a></li>
                <li class="breadcrumb-item active">Product Model List</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
  
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                    <a class="btn btn-primary btn-sm" [routerLink] = "['../addModelId']">
                        <i class="fas fa-plus">
                        </i>
                        Add Product Model
                    </a>
  
                  <div class="card-tools">
                    <div class="input-group input-group-sm" style="width: 150px;">
                      <input type="text" name="table_search" class="form-control float-right" placeholder="Search">
  
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-default">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.card-header -->
                
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        
                        <th>Name</th>
                        <th>Model Id</th>
                        <!-- <th>Status</th> -->
                        <th>Action</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let user of userlist | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                        
                        <td>{{ user.name }}</td>
                        <td>{{ user.modelId }}</td>
                        
                        <!-- <td><span class="tag tag-success"><td>{{ user.description }}</td></span></td> -->
                        <td class="project-actions text-right">
                          <a class="btn btn-primary btn-sm" routerLink="../modelIdDetail/{{ user._id }}">
                            <i class="fas fa-eye">
                            </i>
                            
                        </a>
                          <a class="btn btn-info btn-sm" routerLink="../editModelId/{{ user._id }}">
                              <i class="fas fa-pencil-alt">
                              </i>
                              
                          </a>
                          <a class="btn btn-danger btn-sm"  routerLink="../deleteModelId/{{ user._id }}">
                            <i class="fas fa-trash">
                            </i>
                            
                        </a>
                      </td>
                      
                        
                      </tr>
                     
                    </tbody>
                  </table>
                  <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
                  
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
  
    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
  
    <!-- Main Footer -->
    <footer class="main-footer">
      <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong>
      All rights reserved.
      <div class="float-right d-none d-sm-inline-block">
        <b>Version</b> 3.1.0
      </div>
    </footer>
  </div>