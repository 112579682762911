<style>
    .form-group {
    margin-bottom: 1rem;
    padding: 9px 12px;
}
</style>
<div class="wrapper" >

    <!-- Preloader -->
    <!-- <div class="preloader flex-column justify-content-center align-items-center">
      <img class="animation__wobble" src="assets/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
    </div> -->
  
    <!-- Navbar -->
    <app-header></app-header>
    <!-- /.navbar -->
  
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a href="index3.html" class="brand-link">
        <img src="assets/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-light">Admin</span>
      </a>
  
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image">
            <!-- <img src="assets/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image"> -->
          </div>
          <div class="info">
            <a href="#" class="d-block"></a>
          </div>
        </div>
  
        <!-- SidebarSearch Form -->
        
  
        <app-sidebar></app-sidebar>
      </div>
      <!-- /.sidebar -->
    </aside>
  
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <a class="btn btn-primary btn-sm" [routerLink] = "['/viewUnit']">
                <i class="fas fa-angle-left">
                </i>
                Back 
            </a>
            </div><!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a [routerLink] = "['/dashboard']">Home</a></li>
                <li class="breadcrumb-item active">Unit Details</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
  
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Info boxes -->
          <div class="row">
            <!-- left column -->
            
            <div class="col-md-8 ">
              <!-- general form elements -->
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title">View Device Detail</h3>
                </div>
               
                <!-- /.card-header -->
                <!-- form start -->
                
                  <div class="row">
                    <div class="col-md-12 ">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Unit Id</label>
                            <p> {{unitDetail._id}}</p>
                           
                        </div>
                    </div>
                    <div class="col-md-12 ">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Unit Name</label>
                            <p> {{unitDetail.name}}</p>
                           
                        </div>
                    </div>
                    <div class="col-md-12 ">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Unit Symbol</label>
                            <p> {{unitDetail.unit_symbol}}</p>
                        </div>
                    </div>
                    <div class="col-md-12 ">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Description</label>
                            <p> {{unitDetail.description}}</p>
                        </div>
                    </div>
                </div>
                  <!-- /.card-body -->
  
              </div>
              <!-- /.card -->
  
             
            </div>
            <!--/.col (left) -->
            <!-- right column -->
            
            <!--/.col (right) -->
          </div>
        </div><!--/. container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
  
    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
  
    <!-- Main Footer -->
    <footer class="main-footer">
      <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong>
      All rights reserved.
      <div class="float-right d-none d-sm-inline-block">
        <b>Version</b> 3.1.0
      </div>
    </footer>
  </div>